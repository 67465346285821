/* global FB fb */
import { globalConfig } from "@/v2/core/global-config";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFacebookApi = () => {
  function setupInit(): void {
    window.fbAsyncInit = function (): void {
      FB.init({
        appId: globalConfig.facebookId,
        cookie: true,
        xfbml: true,
        version: "v16.0",
      });
    };
  }

  function loadSdk(): void {
    const id = "facebook-jssdk";

    if (document.getElementById(id)) {
      return;
    }

    const script = document.createElement("script") as HTMLScriptElement;
    script.id = id;
    script.src = "https://connect.facebook.net/en_US/sdk.js";

    const addedScript = document.getElementsByTagName(
      "script"
    )[0] as HTMLScriptElement;
    addedScript.parentNode?.insertBefore(script, addedScript);
  }

  function startFacebook(): void {
    setupInit();
    loadSdk();
  }

  function onFacebookLogin(
    callback: (response: fb.StatusResponse) => Promise<void>,
    options?: fb.LoginOptions
  ): void {
    FB.login(callback, options ?? {});
  }

  return {
    startFacebook,
    onFacebookLogin,
  };
};
