import { getClient } from "./client";
import { errorHandler } from "./error-handler";

export async function deleteRequest<T>(url: string): Promise<T> {
  try {
    const response = await getClient().delete(url);

    return await response.json<T>();
  } catch (err: unknown) {
    throw await errorHandler(err);
  }
}
