import { IFetcherErrorContext } from "@/core/fetcher/http-error-serializer";

export class FetcherFormValidationError extends Error {
  public readonly context: IFetcherErrorContext;
  public readonly errors: Record<string, Array<string>>;

  constructor(
    errors: Record<string, Array<string>>,
    context: IFetcherErrorContext
  ) {
    const errorMessage = `Request failed due to data validation error`;
    super(errorMessage);

    this.name = "FetcherFormValidationError";
    this.message = errorMessage;
    this.context = context;
    this.errors = errors;
  }
}
