import type { Options } from "ky";
import { getClient } from "./client";
import { errorHandler } from "./error-handler";

type IBody = Options["json"];

export async function putRequest<T, U extends IBody = undefined>(
  url: string,
  config?: { body?: U }
): Promise<T> {
  try {
    const options: Options = {};

    if (config?.body) {
      options.json = config.body;
    }

    const response = await getClient().put(url, options);

    return await response.json<T>();
  } catch (err: unknown) {
    throw await errorHandler(err);
  }
}
