import { IFetcherErrorContext } from "./http-error-serializer";

export class FetcherBusinessRuleError extends Error {
  public readonly context: IFetcherErrorContext;
  public readonly code: string;
  public readonly meta: Record<string, any>;

  constructor(
    code: string,
    message: string,
    context: IFetcherErrorContext,
    meta: Record<string, any>
  ) {
    super(message);

    this.name = "FetcherBusinessRuleError";
    this.message = message;
    this.context = context;
    this.code = code;
    this.meta = meta;
  }
}
